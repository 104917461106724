.body {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #dcdcdc;
}
.login {
    font-weight: bold;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #1da1f2;
    padding: 10px;
    border: black 1px solid;
    -webkit-box-shadow: 5px 5px 16px 0px rgba(0,0,0,0.39);
    box-shadow: 5px 5px 16px 0px rgba(0,0,0,0.39);
    border-radius: 5px;
    cursor: pointer;
    transition: .5s;
    text-decoration: none;
    color: #000;
}
.login:hover {
    background-color: #73c5ff;
    font-size: 21px;
}
.userdetails .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: white 3px solid;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-bottom: 0;
}
.userdetails p {
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin-top: 0;
}
.userdetails .emoji {
    width: 25px;
    transform: translateY(5px);
}

.tweet-content {
    /* make a cool textarea */
    width: 25%;
    height: 100px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    resize: none;
    outline: none;
    background-color: #f2f2f2;
    transition: .5s;
}
.tweet-length {
    height: 3px;
    transform: translateY(5px);
}
.ouai {
    position: relative;
    left: 37.5%;
    width: 25%;
}
.tweet .login {
    margin-top: 5px;
    font-size: 15px;
}
